<script>
export default {
  name: "BreadcrumbsUi",
  props: {
    customClass: {
      type: String,
      default: "",
    },
    page: {
      type: Array,
      required: true,
    },
    bannerHead: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      breadcrumbs: [
        {
          id: 1,
          link: "/",
          name: "Главная",
        },
      ],
      banner: "videos",
      apiDomain: this.$store.getters.getApiDomain,
    };
  },

  methods: {},
  mounted() {
    this.banner = window.location.pathname.match(/\/([^/]+)/)[1];
    console.log("banner", this.banner);

    for (let p of this.page) {
      this.breadcrumbs.push({
        id: this.breadcrumbs.length + 1,
        link: "/" + p.link,
        name: p.name,
      });
    }
  },
};
</script>

<template>
  <div :class="'breadcrumbs'">
    <div v-if="bannerHead" class="breadcrumbs__wrapper"
    :style="`background: url(`+ apiDomain + `web/uploads/` + bannerHead + `) no-repeat center center / cover`"
    >

    </div>
    <div
    v-else
      class="breadcrumbs__wrapper"
      :style="`background: url(${require('@/assets/image/' +
        banner +
        '-ban.png')}) no-repeat center center / cover`"
    ></div>
    <div class="container">
      <div class="breadcrumbs__content">
        <div class="breadcrumbsLinks">
          <a
            class="breadcrumbs__item"
            v-for="breadcrumb in breadcrumbs"
            :key="breadcrumb.id"
            :href="breadcrumb.link"
          >
            {{ breadcrumb.name }}
          </a>
        </div>
        <div class="head-title">
          <h1 class="head-h1">{{ title }}</h1>
        </div>
        <div v-if="date" class="date">{{ date }}</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.head-h1 {
  color: #333;
  font-family: Onest;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  max-width: 574px;
}
.date {
  color: #333;
  font-family: Onest;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.breadcrumbs__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: max-content;
  backdrop-filter: blur(5px);
}
.breadcrumbs {
  padding-top: 87px;
}
.breadcrumbs__wrapper {
  padding: 40px;
  margin-bottom: 20px;
  height: 110px;
}
.breadcrumbs__wrapper .container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.breadcrumbsLinks {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 18px;
}
.breadcrumbs__item {
  color: #333;
  font-family: Onest;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  white-space: nowrap;
}
.breadcrumbs__item:not(:last-child):after {
  content: "/";
  position: absolute;
  top: 0;
  right: -12px;
}

.custom-breadcrumbs {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 1024px) {
  .head-h1 {
    font-size: 26px;
  }
  .container {
    padding: 0 20px;
    overflow: hidden;
  }
}
@media screen and (max-width: 560px) {
  .custom-breadcrumbs {
    display: block;
  }
}
</style>
