<script>
import $ from "jquery";
export default {
  name: "BtnRed",
  methods: {
    animateButton(e) {
      console.log("click");

      e.target.classList.remove("animate");

      e.target.classList.add("animate");
      setTimeout(function () {
        e.target.classList.remove("animate");
      }, 700);
    },
  },

  mounted() {
    $(".btn-red").on("click", this.animateButton);
  },
};
</script>

<template>
  <a class="btn-red">
    <slot></slot>
  </a>
</template>

<style scoped>
.btn-red{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
</style>