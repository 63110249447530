<script>
import PrelouderComp from "./components/ui/PrelouderComp.vue";
import emitter from "./eventBus"; // Импортируем глобальный эмиттер
import WidjetAudio2 from "./components/elements/WidjetAudio2.vue";
import { useCookies } from "vue3-cookies";

export default {
  name: "App",
  components: {
    PrelouderComp,
    WidjetAudio2,
  },
  data() {
    return {
      loading: true,
      audioWidjet: null,
      observers: [], // Для хранения всех Observer
      mutationObserver: null, // Для отслеживания изменений в DOM
      flagPlay: false
    };
  },
  methods: {
    showPreloader() {
      this.loading = true;
    },
    hidePreloader() {
      this.loading = false;
    },
    checkCookie() {
      let user = this.cookies.isKey("user") ? this.cookies.get("user") : false;
      console.log("UserAPP", user);
      if (user) {
        this.$store.commit("setUserData", user);
      }
    },

    checkLocalStorage() {
      const audioId = localStorage.getItem("audioId");
      const playing = localStorage.getItem("audioPlay");
      if (audioId && playing == "playing") {
        this.goPlay(audioId);
      }
    },
    goPlay(audioId) {
      this.audioWidjet = audioId; // Проверка и установка audioWidjet
    },
  },
  computed: {
    audioS() {
      let audio = localStorage.getItem("audioId");
      return audio ? audio : null;
    },
  },
  watch: {
    audioS() {
      this.goPlay();
    },
  },
  mounted() {
    let playing = localStorage.getItem("audioPlay");
    if (playing == "playing") {
      localStorage.setItem("audioPlay", "paused");
    }
    this.checkCookie();
      this.interval = setInterval(() => {
      this.checkLocalStorage();
    }, 300);
    
  },
  beforeUnmount() {
    emitter.off("show-preloader", this.showPreloader);
    emitter.off("hide-preloader", this.hidePreloader);
  },
  created() {
    emitter.on("show-preloader", this.showPreloader);
    emitter.on("hide-preloader", this.hidePreloader);
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
};
</script>

<template>
  <PrelouderComp v-if="loading" />
  <div class="wrapper"><router-view></router-view></div>
  <WidjetAudio2
    v-if="audioWidjet !== null"
    :item="audioWidjet"
    @closeWidjet="audioWidjet = null"
  />
</template>