<script>
import { Ckeditor } from "@ckeditor/ckeditor5-vue";
import {
  ClassicEditor,
  Image,
  ImageToolbar,
  ImageCaption,
  ImageUpload,
  FileRepository,
  SimpleUploadAdapter,
  ImageStyle,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
} from "ckeditor5";
import "ckeditor5/ckeditor5.css";
import axios from "axios";
export default {
  name: "UniversalPage",
  components: {
    Ckeditor,
  },
  props: {
    propsPage: {
      type: String,
    },
    item: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      formData: {
        image: "",
        text: "",
        theme: "",
        type: "text",
        shortText: "",
        audio: "",
        videoUrl: "",
        categorieId: "",
        bookSrc: "",
      },
      itemData: {},
      imagePreview: null,
      srcPhoto: null,
      dopBannerSrc: null,
      dopBannerSrc2: null,
      headerBannerSrc: null,
      bookSrc: null,
      sizePdf: null,
      dopQuote: "",
      themesReset: [],
      categoriesReset: [],
      state: {
        content: "",
      },
      editorConfig: {
        plugins: [
          Bold,
          Essentials,
          Italic,
          Mention,
          Paragraph,
          Undo,
          Image,
          ImageToolbar,
          ImageCaption,
          ImageStyle,
          ImageUpload,
          FileRepository,
          SimpleUploadAdapter,
        ],
        toolbar: [
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "|",
          "insertImage",
          "undo",
          "redo",
        ],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
          ],
          styles: ["full", "side"],
        },
        simpleUpload: {
          uploadUrl:
            this.apiUrl +
            "upload" +
            "&auth=$" +
            this.$store.getters.getUser.username +
            ":$" +
            this.$store.getters.getUser.auth_key, // Укажите сервер для загрузки
          headers: {
            "X-CSRF-TOKEN": "CSRF-Token",
            Authorization: "Bearer <JSON Web Token>",
            "Content-Type": "multipart/form-data",
          },

          fieldName: "UploadForm[file]",
          folder: "users/image",
        },
      },
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      flagCategry: false,
    };
  },

  methods: {
    // Метод для обработки изменения изображения
    async uploadPhoto(
      event,
      dopBanner = false,
      headerBanner = false,
      dopBanner2 = false
    ) {
      let file;
      if (headerBanner) {
        let item = document.getElementById("headerBanner");
        file = item.files[0];
      }
      if (dopBanner) {
        let item = document.getElementById("dopBanner");
        file = item.files[0];
      } else if (dopBanner2) {
        let item = document.getElementById("dopBanner2");
        file = item.files[0];
      } else if (!dopBanner && !headerBanner && !dopBanner2) {
        file = event.target.files[0];
      }

      if (!file) {
        return;
      }

      let validFileTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
        "image/svg+xml",
      ];
      if (!validFileTypes.includes(file.type)) {
        alert("Можно загружать только файлы формата JPG или PNG.");
        return;
      }

      let formData = new FormData();
      formData.append("UploadForm[file]", file);
      formData.append("folder", "users/avatar");
      formData.append("filenamePrefix", "avatar_");

      let authGet = `&auth=${this.user.username}:${this.user.auth_key}`;

      try {
        let response = await axios.post(
          this.apiUrl + "upload" + authGet,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Фото успешно загружено:", response.data);
        if (dopBanner) {
          this.dopBannerSrc = response.data;
        } else if (headerBanner) {
          this.headerBannerSrc = response.data;
        } else if (dopBanner2) {
          this.dopBannerSrc2 = response.data;
        } else {
          this.srcPhoto = response.data;
        }
      } catch (error) {
        console.error("Ошибка:", error);
        alert("Ошибка при загрузке фото. Попробуйте еще раз.");
      }
    },
    async uploadBook(event) {
      let file = event.target.files[0];

      if (!file) {
        return;
      }

      let validFileTypes = [
        "application/pdf", // PDF
        "application/msword", // Microsoft Word (DOC)
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // Microsoft Word (DOCX)
        "application/vnd.ms-excel", // Microsoft Excel (XLS)
        "application/vnd.ms-powerpoint", // Microsoft PowerPoint (PPT)
        "application/vnd.openxmlformats-officedocument.presentationml.presentation", // Microsoft PowerPoint (PPTX)
      ];

      if (!validFileTypes.includes(file.type)) {
        alert(
          "Можно загружать только файлы формата PDF, DOC, DOCX, PPT или PPTX."
        );
        return;
      }

      // Получаем размер файла в килобайтах (KB)
      let fileSizeKB = file.size / 1024;

      // Переменная для хранения размера файла
      let fileSizeDisplay;

      // Если размер файла больше 1024 KB, переводим в MB
      if (fileSizeKB > 1024) {
        let fileSizeMB = fileSizeKB / 1024;
        fileSizeDisplay = `${fileSizeMB.toFixed(2)} MB`;
      } else {
        fileSizeDisplay = `${fileSizeKB.toFixed(2)} KB`;
      }

      console.log(`Размер файла: ${fileSizeDisplay}`);

      let formData = new FormData();
      formData.append("UploadForm[file]", file);
      formData.append("folder", "books/book");
      formData.append("filenamePrefix", "book_");

      let authGet = `&auth=${this.user.username}:${this.user.auth_key}`;

      try {
        let response = await axios.post(
          this.apiUrl + "upload" + authGet,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log("Книга успешно загружена:", response.data);
        this.bookSrc = response.data;

        // Сохраняем размер файла в переменную sizePdf
        this.sizePdf = fileSizeDisplay;
      } catch (error) {
        console.error("Ошибка:", error);
        alert("Ошибка при загрузке файла. Попробуйте еще раз.");
      }
    },
    // Метод для обработки изменения аудио
    async onAudioChange(event) {
      let file = event.target.files[0];

      if (!file) {
        return;
      }
      let validFileTypes = ["audio/mpeg", "audio/ogg", "audio/wav"];
      if (!validFileTypes.includes(file.type)) {
        alert("Можно загружать только файлы формата MP3, OGG или WAV.");
        return;
      }

      let formData = new FormData();
      formData.append("UploadForm[file]", file);
      formData.append("folder", "users/audio");
      formData.append("filenamePrefix", "audio_");
      let authGet = `&auth=${this.user.username}:${this.user.auth_key}`;
      try {
        let response = await axios.post(
          this.apiUrl + "upload" + authGet,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("Аудио успешно загружено:", response.data);
        this.formData.audio = response.data;
      } catch (error) {
        console.error("Ошибка:", error);
      }
    },
    getCategories() {
      if (
        !this.flagCategry &&
        this.propsPage !== "news" &&
        this.propsPage !== "theme"
      ) {
        let authGet = `&auth=${this.user.username}:${this.user.auth_key}`;
        axios
          .get(
            this.apiUrl +
              "api-" +
              this.propsPage +
              "-category/get-list" +
              authGet
          )
          .then((response) => {
            this.categoriesReset = response.data.categories;
            console.log("getCategories", this.categoriesReset);
          })
          .catch((error) => {
            console.error("Error getCategories:", error);
          });
      }
    },

    saveContent() {
      let authGet = `&auth=${this.user.username}:${this.user.auth_key}`;
      if (this.flagCategry) {
        let params = {
          name: this.formData.title,
          description: this.formData.shortText,
        };
        axios
          .post(
            this.apiUrl + "api-" + this.propsPage + "/set" + authGet,
            params
          )
          .then((response) => {
            console.log("saveContent", response);
          });
      } else {
        let params = {};
        if (this.propsPage === "news") {
          params = {
            theme_id: this.formData.theme,
            author_id: this.user.user_id,
            title: this.formData.title,
            description: this.formData.shortText,
            content: this.formData.text,
            date_add: new Date(),
            title_photo: this.srcPhoto,
            short_text: this.formData.shortText,
            text: this.formData.text,
          };
        } else if (this.propsPage === "video") {
          params = {
            theme_id: this.formData.theme,
            category_id: this.formData.categorieId,
            title: this.formData.title,
            description: this.formData.shortText,
            link: this.formData.link,
            poster: this.srcPhoto,
            date_add: new Date(),
          };
        } else if (this.propsPage === "podcast") {
          params = {
            theme_id: this.formData.theme,
            category_id: this.formData.categorieId,
            title: this.formData.title,
            description: this.formData.shortText,
            link: this.formData.audio,
            date_add: new Date(),
          };
        } else if (this.propsPage === "theme") {
          params = {
            id: this.itemData.id,
            name: this.formData.title,
            description: this.formData.shortText,
            podcast_banner: this.srcPhoto,
            banner: this.dopBannerSrc,
            banner_full: this.dopBannerSrc2,
            img: this.headerBannerSrc,
            quote: this.dopQuote,
            date_add: new Date(),
          };
        } else if (this.propsPage === "book") {
          params = {
            theme_id: this.formData.theme,
            title: this.formData.title,
            category_id: this.formData.categorieId,
            format: "PDF",
            size: this.sizePdf,
            link: this.bookSrc,
            poster: this.srcPhoto,
            date_add: new Date(),
          };
        }
        console.log("saveContent", params);
        if (this.item) {
          params.id = this.item.id;
          axios
            .post(
              this.apiUrl + "api-" + this.propsPage + "/update" + authGet,
              params
            )
            .then((response) => {
              console.log("saveContentUpdate", response);
            });
        } else {
          axios
            .post(
              this.apiUrl + "api-" + this.propsPage + "/set" + authGet,
              params
            )
            .then((response) => {
              console.log("saveContent", response);
            });
        }
      }
    },
    getThemes() {
      let authGet = `&auth=${this.user.username}:${this.user.auth_key}`;
      axios
        .get(this.apiUrl + "api-theme/get-list" + authGet)
        .then((response) => {
          this.themesReset = response.data.themes;
          console.log("getThemes", this.themesReset);
        });
    },
  },
  mounted() {
    if (this.propsPage.includes("-category")) {
      this.flagCategry = true;
    }
    this.formData.type = this.propsPage;
    console.log("formData", this.propsPage);
    this.getCategories();
    this.getThemes();

    if (this.item) {
      this.itemData = this.item;
      this.formData.shortText =
        this.itemData?.description || this.itemData?.short_text;
      this.formData.text = this.itemData?.content || this.itemData?.text;
      this.formData.title = this.itemData?.title || this.itemData?.name;
      this.formData.categorieId = this.itemData?.category_id;
      this.formData.link = this.itemData?.link;
      this.formData.audio = this.itemData?.link;
      this.srcPhoto =
        this.itemData?.title_photo ||
        this.itemData?.poster ||
        this.itemData?.podcast_banner ||
        null;
      this.dopBannerSrc = this.itemData?.banner || null;
      this.dopBannerSrc2 = this.itemData?.banner_full || null;
      this.dopQuote = this.itemData?.quote || null;
    }
  },
};
</script>


<template>
  <div class="content-editor">
    <div class="form-group">
      <label for="title">Наименование</label>
      <input
        type="text"
        v-model="formData.title"
        value="{{itemData?.title || itemData?.name}}"
        placeholder="Название"
      />
    </div>
    <div
      v-if="
        propsPage === 'podcast-category' ||
        propsPage === 'video-category' ||
        propsPage === 'news-category'
      "
      class="form-group"
    >
      <label for="text">Краткое описание</label>
      <textarea
        v-model="formData.shortText"
        placeholder="Введите текст"
        value="{{itemData?.description || itemData?.short_text}}"
        rows="5"
      ></textarea>
    </div>
    <!-- Поле для загрузки изображения -->

    <div v-if="propsPage === 'theme'" class="form-group">
      <label for="image">Баннер страницы темы Шапка</label>
      <input
        accept=".jpg,.jpeg,.png,.gif,.webp,.svg"
        type="file"
        id="headerBanner"
        @change="uploadPhoto(event, false, true)"
      />
      <div v-if="!headerBannerSrc">
        <img
          v-if="itemData?.img"
          :src="apiDomain + 'web/uploads/' + itemData?.img"
          alt="Preview"
          class="image-preview"
        />
      </div>
      <div v-if="headerBannerSrc">
        <img
          :src="apiDomain + 'web/uploads/' + headerBannerSrc"
          alt="Preview"
          class="image-preview"
        />
      </div>
    </div>

    <div class="form-group">
      <label v-if="propsPage != 'theme'" for="image">Изображение</label>
      <label v-if="propsPage === 'theme'" for="image">Баннер подкастов</label>
      <input
        accept=".jpg,.jpeg,.png,.gif,.webp,.svg"
        type="file"
        @change="uploadPhoto"
      />
      <div v-if="!srcPhoto">
        <img
          v-if="
            itemData?.title_photo ||
            itemData?.poster ||
            itemData?.podcast_banner
          "
          :src="
            apiDomain + 'web/uploads/' + itemData?.title_photo ||
            itemData?.poster ||
            itemData?.podcast_banner
          "
          alt="Preview"
          class="image-preview"
        />
      </div>
      <div v-if="srcPhoto">
        <img
          :src="apiDomain + 'web/uploads/' + srcPhoto"
          alt="Preview"
          class="image-preview"
        />
      </div>
    </div>
    <div v-if="propsPage === 'book'" class="form-group">
      <label for="image">Файл книги</label>
      <input
        accept=".pdf,.epub,.fb2"
        type="file"
        id="fileBook"
        @change="uploadBook"
      />
    </div>
    <div v-if="propsPage === 'theme'" class="form-group">
      <label for="image">Баннер страницы темы</label>
      <input
        accept=".jpg,.jpeg,.png,.gif,.webp,.svg"
        type="file"
        id="dopBanner"
        @change="uploadPhoto(event, true)"
      />
      <div v-if="!dopBannerSrc">
        <img
          v-if="
            itemData?.title_photo ||
            itemData?.poster ||
            itemData?.podcast_banner ||
            itemData?.banner
          "
          :src="
            apiDomain + 'web/uploads/' + itemData?.title_photo ||
            itemData?.poster ||
            itemData?.podcast_banner ||
            itemData?.banner
          "
          alt="Preview"
          class="image-preview"
        />
      </div>
      <div v-if="dopBannerSrc">
        <img
          :src="apiDomain + 'web/uploads/' + dopBannerSrc"
          alt="Preview"
          class="image-preview"
        />
      </div>
    </div>
    <div v-if="propsPage === 'theme'" class="form-group">
      <label for="image">Баннер для фразы</label>
      <input
        accept=".jpg,.jpeg,.png,.gif,.webp,.svg"
        type="file"
        id="dopBanner2"
        @change="uploadPhoto(event, false, false, true)"
      />
      <div v-if="!dopBannerSrc2">
        <img
          v-if="
            itemData?.banner_full
          "
          :src="
            apiDomain + 'web/uploads/' + itemData?.banner_full
          "
          alt="Preview"
          class="image-preview"
        />
      </div>
      <div v-if="dopBannerSrc2">
        <img
          :src="apiDomain + 'web/uploads/' + dopBannerSrc2"
          alt="Preview"
          class="image-preview"
        />
      </div>
    </div>
    <div v-if="propsPage === 'theme'" class="form-group">
      <label for="title">Фраза анимации</label>
      <textarea
        v-model="dopQuote"
        placeholder="Введите текст"
        rows="5"
      ></textarea>
    </div>
    <div class="form-group">
      <label for="text">Краткое описание</label>
      <textarea
        v-model="formData.shortText"
        placeholder="Введите текст"
        rows="5"
      ></textarea>
      <!-- <quill-editor
        v-model="state.content"
        
      /> -->
    </div>
    <!-- Текстовое поле или редактор текста -->
    <div v-if="formData.type === 'news'" class="form-group">
      <label for="text">Основной контент</label>
      <ckeditor
        v-if="formData.type === 'news'"
        v-model="formData.text"
        :editor="editor"
        :config="editorConfig"
      />
    </div>

    <!-- Селектор темы -->
    <div v-if="propsPage != 'theme'" class="form-group">
      <label for="theme">Выберите тему</label>
      <select v-model="formData.theme">
        <option value="">Выберите тему</option>
        <option v-for="theme in themesReset" :key="theme" :value="theme.id">
          {{ theme.name }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="theme">Выберите категорию</label>
      <select v-model="formData.categorieId">
        <option value="">Выберите тему</option>
        <option v-for="cat in categoriesReset" :key="cat.id" :value="cat.id">
          {{ cat.name }}
        </option>
      </select>
    </div>

    <!-- Дополнительные поля в зависимости от типа -->
    <div v-if="formData.type === 'podcast'" class="form-group">
      <label for="audio">Аудиофайл</label>
      <input accept=".mp3,.ogg,.wav" type="file" @change="onAudioChange" />
    </div>

    <div v-if="formData.type === 'video'" class="form-group">
      <label for="video">Ссылка на видео (iframe)</label>
      <input
        v-model="formData.link"
        placeholder="Вставьте ссылку на iframe видео"
      />
    </div>

    <!-- Кнопка для сохранения -->
    <button @click="saveContent" class="btn-white">Сохранить</button>
  </div>
</template>
  <style scoped>
.content-editor {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
label {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #333;
}
input,
textarea,
select {
  background: #f1f1f1;
  border: 1px solid #5f22c1;
  padding: 5px;
  min-height: 34px;
}
</style>
