<script>
import HomeSliderBlock from "../blocks/HomeSliderBlock.vue";
import HeaderBlock from "../blocks/HeaderBlock.vue";
import HomeThemBlock from "../blocks/HomeThemBlock.vue";
import HomeAboutBlock from "../blocks/HomeAboutBlock.vue";
import HomeCitataBlock from "../blocks/HomeCitataBlock.vue";
import HomeVideoBlock from "../blocks/HomeVideoBlock.vue";
import homeTestBlock from "../blocks/homeTestBlock.vue";
import HomeBaseBlock from "../blocks/HomeBaseBlock.vue";
import PromoTheme from "../blocks/PromoTheme.vue";
import homeNewsBlock from "../blocks/homeNewsBlock.vue";
import SubscribeBlock from "../blocks/SubscribeBlock.vue";
import FooterBlock from "../blocks/FooterBlock.vue";
export default {
  name: "HomePage",
  components: {
    HomeSliderBlock,
    HeaderBlock,
    HomeThemBlock,
    HomeAboutBlock,
    HomeCitataBlock,
    HomeVideoBlock,
    homeTestBlock,
    HomeBaseBlock,
    PromoTheme,
    homeNewsBlock,
    SubscribeBlock,
    FooterBlock
  },
};
</script>
<template>
  <HeaderBlock />
  <HomeSliderBlock />
  <HomeThemBlock />
  <HomeAboutBlock />
  <HomeCitataBlock />   
  <HomeVideoBlock />
  <homeTestBlock />
  <HomeBaseBlock />
  <PromoTheme />
  <homeNewsBlock />
  <SubscribeBlock />
  <FooterBlock />

</template>