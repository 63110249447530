<script>
import SideBar from "../blocks/SideBar.vue";
import HeaderAcc from "../blocks/HeaderAcc.vue";
import ProfileAcc from "../blocks/ProfileAcc.vue";
import axios from "axios";
export default {
  name: "AccountPage",
  components: {
    SideBar,
    HeaderAcc,
    ProfileAcc,
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      userInfo: null,
    };
  },
  methods: {
    getUserInfo() {
      let authGet = `&auth=${this.user.username}:${this.user.auth_key}`;
      axios
        .get(this.apiUrl + "api-user/get-user" + authGet)
        .then((response) => {
          console.log("getUserInfo", response.data.user);
          this.userInfo = response.data.user;
        })
    },
  },
  mounted() {
    this.getUserInfo();
  },
  
};
</script>

<template>
  <div class="wrapperAcc">
    <div class="sidebar">
      <SideBar :user="user" />
    </div>
    <div class="info">
      <HeaderAcc />
      <ProfileAcc :userP="userInfo" />
    </div>
  </div>
</template>

<style scoped>
.wrapperAcc {
  display: flex;
  background: #f5f7f8;
  padding-right: 30px;
  gap: 51px;
}
.sidebar {
  width: 308px;
  position: sticky;
  height: 100vh;
  flex-shrink: 0;
  background: url(@/assets/image/sidebar.png);
}
.info {
  width: 100%;
  max-width: 954px;
}
</style>