ue
Копировать код
<script>
import validateEmail from "@/directives/validateEmail.js";
import validatePhone from "@/directives/validatePhone.js";
import TabsBlock from "../ui/TabsBlock.vue";
import { MaskInput } from "vue-3-mask";
import axios from "axios";
import Cookies from "js-cookie";
import router from "@/router/router";

export default {
  name: "LoginBlock",
  directives: {
    validateEmail,
    validatePhone,
  },
  components: {
    TabsBlock,
    MaskInput,
  },
  data() {
    return {
      tabsArray: ["По номеру", "По почте", "Vk", "Yandex"],
      message: "",
      error: "",
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      isEmailValid: false,
      isPhoneValid: false,
      inputUsername: "",
    };
  },
  methods: {
    updateEmailValidity(isValid) {
      this.isEmailValid = isValid;
    },
    updatePhoneValidity(isValid) {
      this.isPhoneValid = isValid;
    },
    popupToogle() {
      const popup = document.querySelector(".popup");
      popup.classList.toggle("active");
    },
    switchTo(type) {
      this.$emit("switchTo", type);
    },
    changeTab(tab) {
      this.message = tab;
    },
    userLogin() {
      if (!this.isEmailValid) {
        this.error = "Некорректная почта";
        this.$emit("erroruser", this.error);
        return;
      }
      this.error = "";
      let authGet = `&auth=${this.user.username}:${this.user.auth_key}`;

      this.inputPassword = document.querySelector(".passInput").value;
      let params = {
        username: this.inputUsername,
        password: this.inputPassword,
      };

      axios
        .post(this.apiUrl + "api-user/login" + authGet, params, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (response.data.status) {
            this.$store.commit("setUserData", response.data.user);
            let jsonUser = JSON.stringify(response.data.user);
            Cookies.set("user", jsonUser, { expires: 7 });
            this.$emit("registerSuccess");
            router.push("/account");
          } else {
            this.error = response.data.error;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // VK ID авторизация
    initVKID() {
      const script = document.createElement("script");
      script.src = "https://unpkg.com/@vkid/sdk@<3.0.0/dist-sdk/umd/index.js";
      script.onload = () => {
        if ("VKIDSDK" in window) {
          const VKID = window.VKIDSDK;

          VKID.Config.init({
            app: 52457533,
            redirectUrl: "https://media495.ru/login",
            responseMode: VKID.ConfigResponseMode.Callback,
            source: VKID.ConfigSource.LOWCODE,
          });

          const oneTap = new VKID.OneTap();
          oneTap
            .render({
              container: this.$refs.vkContainer,
              showAlternativeLogin: true,
            })
            .on(VKID.WidgetEvents.ERROR, this.vkidOnError)
            .on(VKID.OneTapInternalEvents.LOGIN_SUCCESS, this.onLoginSuccess);
        }
      };
      document.head.appendChild(script);
    },
    onLoginSuccess(payload) {
      const code = payload.code;
      const deviceId = payload.device_id;

      window.VKIDSDK.Auth.exchangeCode(code, deviceId)
        .then(this.vkidOnSuccess)
        .catch(this.vkidOnError);
    },
    vkidOnSuccess(data) {
      console.log("Успешная авторизация", data);
      axios
        .get("https://api.vk.com/method/users.get", {
          params: {
            access_token: data.access_token,
            v: "5.199",
          },
        })
        .then((response) => {
          console.log("Информация о пользователе:", response.data);
        })
        .catch((error) => {
          console.log("Ошибка при получении данных пользователя:", error);
        });
    },
    vkidOnError(error) {
      console.error("Ошибка авторизации через VK ID", error);
    },
  },
  mounted() {
    // Инициализация VK ID авторизации, если выбран таб "Vk"
    if (this.message === "Vk") {
      this.initVKID();
    }
  },
  watch: {
    // Следим за изменением таба и загружаем виджет VK
    message(newVal) {
      if (newVal === "Vk") {
        this.initVKID();
      }
    },
  },
};
</script>

<template>
  <div class="form login__form">
    <div class="head-h3">Вход</div>
    <TabsBlock
      @changeTab="changeTab"
      :tabsArray="tabsArray"
      :curTab="'По почте'"
    ></TabsBlock>

    <!-- Вход по почте -->
    <div v-if="message == 'По почте'" class="loginItem">
      <div class="form__content">
        <div class="form__item">
          <label class="form__label">Электронная почта*</label>
          <input
            type="text"
            class="form__input loginInput"
            v-model="inputUsername"
            v-validate-email="updateEmailValidity"
            readonly
            onfocus="this.removeAttribute('readonly')"
          />
        </div>
        <div class="form__item">
          <label class="form__label">Пароль</label>
          <input
            type="password"
            class="form__input passInput"
            readonly
            onfocus="this.removeAttribute('readonly')"
          />
          <a @click="popupToogle" class="forgot">Забыли пароль?</a>
        </div>
      </div>
      <div class="form__footer">
        <button @click="userLogin" class="btn btn-blue">Вход</button>
      </div>
    </div>

    <!-- Вход по номеру -->
    <div v-if="message == 'По номеру'" class="loginItem">
      <div class="form__content">
        <div class="form__item">
          <label class="form__label">Номер телефона*</label>
          <MaskInput
            v-validate-phone="updatePhoneValidity"
            v-model="inputUsername"
            class="form__input phone-input"
            mask="+7(###) ###-##-##"
          />
        </div>
        <div class="form__item">
          <label class="form__label">Пароль</label>
          <input
            type="password"
            class="form__input passInput"
            readonly
            onfocus="this.removeAttribute('readonly')"
          />
          <a @click="popupToogle" class="forgot">Забыли пароль?</a>
        </div>
      </div>
      <div class="form__footer">
        <button @click="userLogin" class="btn btn-blue">Вход</button>
      </div>
    </div>

    <!-- Вход через ВКонтакте -->
    <div v-if="message == 'Vk'" ref="vkContainer" class="loginItem"></div>

    <div class="form__footer">
      <a @click="switchTo('registration')" class="link"
        >Впервые здесь? <span>Зарегистрироваться</span></a
      >
    </div>
  </div>
</template>
<style scoped>
.close {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.login {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.login__content {
  width: 100%;
  max-width: 536px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  padding: 22px 0;
  height: 100%;
  margin: 0 auto;
}
.login__head {
  display: flex;
  flex-direction: column;
  gap: 36px;
}
.logo {
  max-width: 205px;
}
.back {
  color: var(--Black-1200, #2e2e2e);
  font-family: "Suisse Intl";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 36px;
  max-width: 536px;
}
.form__item {
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 536px;
}
.form__label {
  color: var(--Black-1300, #1d1d1b);
  font-family: Onest;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
}
.form__input,
.form__input:autofill {
  border-radius: 2px;
  background: var(--Black-200, #f2f2f2);
  color: var(--Black, #111);
  font-family: Onest;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;

  padding: 14px 16px;
  outline: none;
  border: 1px solid #eaeaea;
  width: 100%;
  max-width: 100%;
}
.form__input:hover,
.form__input:focus {
  border-radius: 2px;
  background: var(--Black-300, #eaeaea);
  list-style: none;
  outline: none;
}
.form__content {
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.form__row {
  display: flex;
  gap: 22px;
  justify-content: space-between;
}
.forgot {
  color: var(--Black-1200, #2e2e2e);
  font-family: Onest;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;

  text-decoration-line: underline;
  margin-top: 8px;
}
.btn-black {
  width: 100%;
}
.link {
  color: var(--Black-1200, #2e2e2e);
  font-family: Onest;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
}
.link span {
  text-decoration-line: underline;
}
.login__image {
  width: 50%;
  flex-shrink: 0;
  height: 100%;
  background: #0850cd;
  position: relative;
  z-index: 2;
}
.login__image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.login__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  flex-shrink: 0;
  padding-right: 40px;
  padding-left: 40px;
  height: 100%;
}
.login-image {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.politic__text {
  color: var(--Black-1200, #2e2e2e);
  font-family: Onest;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
}
.politic__text span {
  text-decoration-line: underline;
}
.popup {
  background: rgba(31, 31, 31, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 0 20px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.popup.active {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}
.popup__content {
  border-radius: 2px;
  background: var(--White, #fff);
  padding: 40px 36px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 33px;
  max-width: 586px;
  width: 100%;
}
.popup__head {
  color: var(--Black-1300, #1d1d1b);
  text-align: center;
  font-family: Onest;
  font-size: 28px;
  font-weight: 400;
  line-height: 20.8px;
}

.politic {
  padding-bottom: 20px;
}

@media screen and (max-width: 960px) {
  .form__row {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .login__image {
    display: none;
  }
  .login__wrapper {
    width: 100%;
    padding-right: 24px;
    padding-left: 24px;
  }
  .login__content {
    max-width: 100%;
  }
  .form {
    width: 100%;
    margin: 0 auto;
  }
  .link {
    font-size: 15px;
  }
  .politic {
    max-width: 536px;
    margin: 0 auto;
  }
}
</style>