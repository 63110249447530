<script>
import onlyCyrillic from "@/directives/onlyCirillick.js";
import validateEmail from "@/directives/validateEmail.js";
import LoginBlock from "../blocks/LoginBlock.vue";
import RegisterBlock from "../blocks/RegisterBlock.vue";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  name: "LoginPage",

  components: {
    LoginBlock,
    RegisterBlock,
  },
  directives: {
    onlyCyrillic,
    validateEmail,
  },
  props: {
    msg: String,
    links: String,
  },
  methods: {
    onEmailChange() {
      let email = document.querySelector(".emailChange");
      if (email.value) {
        let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailPattern.test(email.value)) {
          console.log("Email is valid");
          document.querySelector(".emailValid").classList.remove("default");
        } else {
          console.log("Email is invalid");
          document.querySelector(".emailValid").classList.add("default");
        }
      }
    },
    registerSuccess() {
      this.showDone = true;
    },
    popupToogle() {
      document.querySelector(".popup").classList.toggle("active");
    },
    switchTo(mess) {
      this.message = mess;
      console.log("switchToRegistration", this.msg);
    },

    loginRequest() {
      let login = document.querySelector(".inputLogin");

      this.login = login.value;
    },
    PassRequest() {
      let pass = document.querySelector(".inputPass");

      this.pass = pass.value;
    },
    accountRequest() {
      console.log("accountRequest", this.login, this.pass);
      if (this.login !== "" && this.pass !== "") {
        if (this.login === "admin" && this.pass === "adminadmin") {
          this.$router.push("/account");
        }
      }
    },
    erroruser(error) {
      this.error = error;
      this.errorFlag = true;
    },
    closeError() {
      this.errorFlag = false;
    },
  },
  data() {
    return {
      message: "login",
      login: "",
      pass: "",
      inputUsername: "",
      inputPassword: "",
      inputI: "",
      inputEmail: "",
      sfera: "",
      error: "Danger",
      errorFlag: false,
      jsonUser: "",
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      timeline: null,
      showDone: false,
    };
  },

  mounted() {
    this.switchTo(this.message);
    gsap.registerPlugin(ScrollTrigger);
    let mm = gsap.matchMedia();
    this.timeline = gsap.timeline();
    mm.add("(min-width: 768px)", () => {
      gsap.set(".login__image", {
        width: "100%",
      });
      gsap.set(".login__wrapper", {
        width: "10%",
        opacity: 0,
      });
      gsap.to(".login__image", {
        width: "50%",
        duration: 1,
        delay: 0.1,
      });
      gsap.to(".login__wrapper", {
        width: "50%",
        opacity: 1,
        duration: 1,
        delay: 0.1,
      });
    });
  },
};
</script>

<template>
  <div class="login">
    <div class="login__wrapper">
      <div class="login__content">
        <div v-if="errorFlag" class="alert">
          <div class="alert__text">
            {{ error }}
          </div>
          <div @click="closeError" class="alert__close">
            <svg
              data-v-cd8a3306=""
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                data-v-cd8a3306=""
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="#333333"
              ></path>
            </svg>
          </div>
        </div>
        <div v-if="showDone" class="done">
          <div class="done__text">
            На почту отправлено письмо с подтверждением.
          </div>
        </div>
        <login-block
          v-if="message == 'login'"
          @erroruser="erroruser"
          @switchTo="switchTo"
        ></login-block>
        <register-block
          v-if="message == 'registration'"
          @switchTo="switchTo"
          @erroruser="erroruser"
          @registerSuccess="registerSuccess"
        ></register-block>

        <div class="politic">
          <div v-if="message == 'registration'" class="politic__text">
            Нажимая кнопку «Зарегистрироваться», я даю согласие на обработку
            персональных данных, в соответствии с <span>Политикой</span>, и
            соглашаюсь с <span>Правилами</span>
          </div>
        </div>
      </div>
    </div>
    <div class="login__image">
      <img src="@/assets/image/login-bg.png" alt="" />
    </div>
  </div>
  <div class="popup">
    <div class="popup__content">
      <a @click="popupToogle" class="close">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M18 6L6 18M6 6L18 18"
            stroke="#545454"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
      <div class="popup__head">Восстановление пароля</div>
      <div class="form__item">
        <label class="form__label">Электронная почта</label>
        <input
          @input="onEmailChange"
          type="text"
          class="form__input emailChange"
        />
      </div>
      <button class="btn btn-blue default emailValid">Восстановить</button>
    </div>
  </div>
</template>

<style scoped>
.alert {
  display: flex;
  justify-content: space-between;
  width: max-content;
  max-width: 100%;
  margin: 0 auto;
  padding: 30px;
  border-radius: 20px;
  background: #ec7777;
  position: relative;
}
.done{
  margin: 0 auto;
  padding: 30px;
  border-radius: 20px;
  background: #77ec81;
  position: relative;
}
.alert__text {
  color: var(--Black-1200, #2e2e2e);
  font-family: Onest;
  font-size: 20px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
}
.alert__close {
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 10px;
}
.close {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.login {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.login__content {
  width: 100%;
  max-width: 536px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  padding: 22px 0;
  height: 100%;
  margin: 0 auto;
}
.login__head {
  display: flex;
  flex-direction: column;
  gap: 36px;
}
.logo {
  max-width: 205px;
}
.back {
  color: var(--Black-1200, #2e2e2e);
  font-family: "Suisse Intl";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 36px;
  max-width: 536px;
}
.form__item {
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 536px;
}
.form__label {
  color: var(--Black-1300, #1d1d1b);
  font-family: Onest;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
}
.form__input,
.form__input:autofill,
.form__input:-webkit-autofill,
.form__input:-internal-autofill-selected {
  border-radius: 2px;
  background: var(--Black-200, #f2f2f2);
  color: var(--Black, #111);
  font-family: Onest;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;

  padding: 14px 16px;
  outline: none;
  border: 1px solid #eaeaea;
  width: 100%;
  max-width: 100%;
}
.form__input:hover,
.form__input:focus {
  border-radius: 2px;
  background: var(--Black-300, #eaeaea);
  list-style: none;
  outline: none;
}
.form__content {
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.form__row {
  display: flex;
  gap: 22px;
  justify-content: space-between;
}
.forgot {
  color: var(--Black-1200, #2e2e2e);
  font-family: Onest;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;

  text-decoration-line: underline;
  margin-top: 8px;
}
.btn-black {
  width: 100%;
}
.link {
  color: var(--Black-1200, #2e2e2e);
  font-family: Onest;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
}
.link span {
  text-decoration-line: underline;
}
.login__image {
  width: 50%;
  flex-shrink: 0;
  height: 100%;
  background: #0850cd;
  position: relative;
  z-index: 2;
}
.login__image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.login__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  flex-shrink: 0;
  padding-right: 40px;
  padding-left: 40px;
  height: 100%;
}
.login-image {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.politic__text {
  color: var(--Black-1200, #2e2e2e);
  font-family: Onest;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
}
.politic__text span {
  text-decoration-line: underline;
}
.popup {
  background: rgba(31, 31, 31, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 0 20px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.popup.active {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}
.popup__content {
  border-radius: 2px;
  background: var(--White, #fff);
  padding: 40px 36px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 33px;
  max-width: 586px;
  width: 100%;
}
.popup__head {
  color: var(--Black-1300, #1d1d1b);
  text-align: center;
  font-family: Onest;
  font-size: 28px;
  font-weight: 400;
  line-height: 20.8px;
}

.politic {
  padding-bottom: 20px;
}

@media screen and (max-width: 960px) {
  .form__row {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .login__image {
    display: none;
  }
  .login__wrapper {
    width: 100%;
    padding-right: 24px;
    padding-left: 24px;
  }
  .login__content {
    max-width: 100%;
  }
  .form {
    width: 100%;
    margin: 0 auto;
  }
  .link {
    font-size: 15px;
  }
  .politic {
    max-width: 536px;
    margin: 0 auto;
  }
}
</style>
